/**
 * English translation resources.
 * Each object correspond to a namespace related to a feature.
 */
let en = {
  common: {
    FULL_NAME: "Rafik Al Shmoury",

    /** navbar */
    ABOUT: "About",
    EXPERIENCE: "Experience",
    PROJECTS: "Projects",
    EDUCATION: "Education",
    LANG: "EN",

    /** Overview */
    HELLO: "Hello, I am",
    ROLE_1: "Software Engineer",
    ROLE_2: "Full Stack Developer",

    /** About Me */
    ABOUT_TITLE: "About Me",
    ABOUT_TEXT: `Innovative Software Engineer with 4+ years as a Full Stack developer. I help companies build end-to-end creative multi tenant platforms. 

      As a Front End Developer I build reusable and responsive front-end components. My crafting tools are 𝗥𝗲𝗮𝗰𝘁, 𝗝𝗮𝘃𝗮𝗦𝗰𝗿𝗶𝗽𝘁, 𝗧𝘆𝗽𝗲𝗦𝗰𝗿𝗶𝗽𝘁, 𝗦𝗔𝗦𝗦.
      
      As a Backend Developer I design and build scalable Nodejs 𝗺𝗶𝗰𝗿𝗼-services. I integrated 3rd party services such as WSO2, Business central, and Auth0. Built in house payment gateway using 𝗠𝗮𝘀𝘁𝗲𝗿𝗰𝗮𝗿𝗱 𝗣𝗮𝘆𝗺𝗲𝗻𝘁 𝗚𝗮𝘁𝗲𝘄𝗮𝘆 𝗦𝗲𝗿𝘃𝗶𝗰𝗲𝘀`,

    /** Experience */
    MODO_JOB_POSITION: "Senior Frontend Engineer",
    MODO_JOB_TITLE: "Modoplus GmbH",
    MODO_JOB_LOCATION: "Hamburg, Remote",
    MODO_JOB_DATE: "Jan 2023 - Present",
    MODO_JOB_BULLET_1:
      "Created modoplus parcel calculator feature to calculate the pricing of certain parcels, improving the customer experience and reducing errors.",
    MODO_JOB_BULLET_2:
      "Created typescript Nextjs 13 template that takes advantage of the new react server components, eslint, prettier, trpc and prisma.",
    MODO_JOB_BULLET_3:
      "Collaborated with the designer to create a styling guide for consistent UI across the application.",
    MODO_JOB_BULLET_4:
      "Refactored APIs from NestJS to trpc APIs in NextJS to optimize performance and scalability.",
    MODO_JOB_BULLET_5:
      "Conducted code reviews and took responsibility for the full stack NextJS application.",

    NASCO_JOB_POSITION: "Full Stack Software Engineer",
    NASCO_JOB_TITLE: "Nasco Insurance Group",
    NASCO_JOB_LOCATION: "Remote",
    NASCO_JOB_DATE: "Jan 2022 - March 2023",
    NASCO_JOB_BULLET_1:
      "Worked on a full digital transformation of the insurance journey in Lebanon and UAE.",
    NASCO_JOB_BULLET_2:
      "Developed a full-fledged insurance platform, moving out from all legacy services and combining the logic under microservices architecture using NodeJS and ExpressJS.",
    NASCO_JOB_BULLET_3:
      "Enforced frontend best practices and atomic design principles that decreased development time by 20%.",
    NASCO_JOB_BULLET_4:
      "Refactored the authentication system from WSO2 to Red Hat SSO.",
    NASCO_JOB_BULLET_5:
      "Integrated DocuSign services to provide platform embedded/email document signature services.",

    FREELANCE_JOB_POSITION: "Software Engineer",
    FREELANCE_JOB_TITLE: "Freelance",
    FREELANCE_JOB_LOCATION: "Remote",
    FREELANCE_JOB_DATE: "June 2018 - Present",
    FREELANCE_JOB_BULLET_1:
      "Developed an internal ordering/communication application for restaurants using React Native, and Firebase Realtime Database.",
    FREELANCE_JOB_BULLET_2:
      "Developed a school grading application using React Native.",
    FREELANCE_JOB_BULLET_3:
      "Developed responsive web applications using React, HTML, CSS, SASS.",
    FREELANCE_JOB_BULLET_4:
      "Developed custom scripts to generate PDFs based on Word templates from spreadsheets using python, python-docx and PyPDF2.",
    FREELANCE_JOB_BULLET_5:
      "Created multiple e-commerce stores using WordPress as CMS, WooCommerce, Mailchimp and custom plugins using PHP. Projects: akl-orient.de larex-global.com letsvip.co.uk ambank.com",

    AREEBA_JOB_POSITION: "Full Stack Software Engineer",
    AREEBA_JOB_TITLE: "Areeba",
    AREEBA_JOB_LOCATION: "Beirut, Lebanon",
    AREEBA_JOB_DATE: "Sept 2020 - Jan 2022",
    AREEBA_JOB_BULLET_1:
      "Developed intuitive graphical user interfaces using React.js and React Native.",
    AREEBA_JOB_BULLET_2:
      "Designed and built scalable NodeJS Express microservices.",
    AREEBA_JOB_BULLET_3:
      "Worked in an Agile development environment as a Full Stack Developer in a functional team to develop leading fintech projects.",

    IDS_JOB_POSITION: "Junior Software Engineer",
    IDS_JOB_TITLE: "Integrated Digital Systems (IDS)",
    IDS_JOB_LOCATION: "Beirut, Lebanon",
    IDS_JOB_DATE: "Oct 2017 - Aug 2018",
    IDS_JOB_BULLET_1:
      "Wrote and maintaining automated tests to ensure code quality and reliability.",
    IDS_JOB_BULLET_2:
      "Created and updating technical documentation, including user manuals and API reference materials.",
    IDS_JOB_BULLET_3:
      "Participated in Agile development methodologies and stand-up meetings",

    /** Projects */
    FIRST_PROJECT_TITLE: "Areeba Merchant Portal",
    FIRST_PROJECT_SUMMARY:
      "Areeba merchant portal offers Custom Branded Invoice Design Recurring Invoicing & Auto-Bill Clients Custom Late-Payment Auto-Reminder Emails etc.",
    SECOND_PROJECT_TITLE: "Zaky",
    SECOND_PROJECT_SUMMARY:
      "Zaky is a digital mobile payment platform that enables consumers, merchants and services providers to make and receive their payments in a seamless and secure way.",
    THIRD_PROJECT_TITLE: "ViaCard",
    THIRD_PROJECT_SUMMARY:
      "ViaCard is the perfect answer to the needs of Lebanese people travelling abroad, shopping online or even those whose children are studying overseas.",
    // FOURTH_PROJECT_TITLE: "Areeba Payment Gateway",
    // FOURTH_PROJECT_SUMMARY:
    //   "Areeba payment gateway is a technology used by merchants to accept debit or credit card purchases from customers.",
    FOURTH_PROJECT_TITLE: "Larex Global",
    FOURTH_PROJECT_SUMMARY:
      "Larex Global is an international coffee trading company in Europe and Middle East.",

    /** Education */
    MASTERS_MAJOR: "Masters of Science in Computer Engineering",
    MASTERS_UNIVERSITY: "Bahcesehir University",
    MASTERS_LOCATION: "Istanbul, Turkey",
    MASTERS_DATE: "2018 - 2020",
    BACHELOR_MAJOR:
      "Bachelor of Science in Computer and Communication Engineering",
    BACHELOR_UNIVERSITY: "Lebanese International University",
    BACHELOR_LOCATION: "Istanbul, Turkey",
    BACHELOR_DATE: "2019 - 2020",
  },
};

export default en;
