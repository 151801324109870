import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en";
import ar from "./locales/ar";
import de from "./locales/de";

i18n.use(initReactI18next).init({
  resources: {
    en: en,
    ar: ar,
    de: de,
  },
  fallbackLng: ["en", "ar", "de"],
  debug: false,

  keySeparator: false, // we use content as keys

  interpolation: {
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
