/**
 * German translation resources.
 * Each object correspond to a namespace related to a feature.
 */
let de = {
  common: {
    FULL_NAME: "Rafik Al Shmoury",

    /** navbar */
    ABOUT: "Über mich",
    EXPERIENCE: "Erfahrung",
    PROJECTS: "Projekte",
    EDUCATION: "Bildung",
    LANG: "DE",
    ABOUT_TEXT: `Innovativer Software-Ingenieur mit mehr als 𝟯 Jahren als Full-Stack-Entwickler. Ich helfe Unternehmen beim Aufbau kreativer mandantenfähiger End-to-End-Plattformen.

    Als Frontend-Entwickler baue ich wiederverwendbare und responsive Frontend-Komponenten. Meine Bastelwerkzeuge sind 𝗥𝗲𝗮𝗰𝘁, 𝗝𝗮𝘃𝗮𝗦𝗰𝗿𝗶𝗽𝘁, 𝗧𝘆𝗽𝗲𝗦𝗰𝗿𝗶𝗽𝘁, 𝗦𝗔𝗦.
    
    Als Backend-Entwickler designe und baue ich skalierbare 𝗡𝗼𝗱𝗲𝗝𝗦 𝗺𝗶𝗰𝗿𝗼-𝘀𝗲𝗿𝘃𝗶𝗰𝗲𝘀. Ich habe Dienste von Drittanbietern wie WSO2, Business Central und Auth0 integriert. Integriertes Zahlungsgateway mit 𝗠𝗮𝘀𝘁𝗲𝗿𝗰𝗮𝗿𝗱 𝗣𝗮𝘆𝗺𝗲𝗻𝘁 𝗚𝗮𝘁𝗲𝘄𝗮𝘆 𝗦𝗲𝗿𝘃𝗶𝗶.`,

    /** Overview */
    HELLO: "Hallo ich bin",
    ROLE_1: "Softwareentwickler",
    ROLE_2: "Full-Stack-Entwickler",
  },
};

export default de;
