/**
 * Arabic translation resources.
 * Each object correspond to a namespace related to a feature.
 */
let ar = {
  common: {
    FULL_NAME: "رفيق الشموري",

    /** navbar */
    ABOUT: "عني",
    EXPERIENCE: "خبرة",
    PROJECTS: "المشاريع",
    EDUCATION: "التعليم",
    LANG: "AR",

    /** Overview */
    HELLO: "مرحبا أنا",
    ROLE_1: "مهندس برمجيات",
    ROLE_2: "مطور برامج كامل",

    /** About Me */
    ABOUT_TITLE: "عني",
    ABOUT_TEXT: `مهندس برمجيات مبتكر مع 4 + سنوات كمطور Full Stack. أساعد الشركات على بناء منصات إبداعية متعددة المستأجرين.

    بصفتي مطورًا للواجهة الأمامية ، أقوم ببناء مكونات أمامية قابلة لإعادة الاستخدام وسريعة الاستجابة. أدوات الصياغة الخاصة بي هي 𝗥𝗲𝗮𝗰𝘁 و 𝗝𝗮𝘃𝗮𝗦𝗰𝗿𝗶𝗽𝘁 و 𝗧𝘆𝗽𝗲𝗦𝗰𝗿𝗶𝗽𝘁 و 𝗦𝗔𝗦𝗦.
    
    بصفتي مطور Backend ، أقوم بتصميم وبناء Nodejs 𝗺𝗶𝗰𝗿𝗼-services قابل للتطوير. لقد قمت بدمج خدمات الجهات الخارجية مثل WSO2 و Business central و Auth0. بوابة دفع منزلية مدمجة باستخدام 𝗠𝗮𝘀𝘁𝗲𝗿𝗰𝗮𝗿𝗱 𝗣𝗮𝘆𝗺𝗲𝗻𝘁 𝗚𝗮𝘁𝗲𝘄𝗮𝘆 𝗦𝗲𝗿𝘃𝗶𝗰𝗲𝘀.`,

    /** Experience */
    // MODO_JOB_POSITION: "Senior Frontend Engineer",
    // MODO_JOB_TITLE: "Modoplus GmbH",
    // MODO_JOB_LOCATION: "Hamburg, Remote",
    // MODO_JOB_DATE: "Jan 2023 - Present",
    // MODO_JOB_BULLET_1:
    //   "Created modoplus parcel calculator feature to calculate the pricing of certain parcels, improving the customer experience and reducing errors.",
    // MODO_JOB_BULLET_2:
    //   "Created typescript Nextjs 13 template that takes advantage of the new react server components, eslint, prettier, trpc and prisma.",
    // MODO_JOB_BULLET_3:
    //   "Collaborated with the designer to create a styling guide for consistent UI across the application.",
    // MODO_JOB_BULLET_4:
    //   "Refactored APIs from NestJS to trpc APIs in NextJS to optimize performance and scalability.",
    // MODO_JOB_BULLET_5:
    //   "Conducted code reviews and took responsibility for the full stack NextJS application.",

    MODO_JOB_POSITION: "Senior Frontend Engineer",
    MODO_JOB_TITLE: "Modoplus GmbH",
    MODO_JOB_LOCATION: "عن بعد",
    MODO_JOB_DATE: "يناير 2023 - الحالي",
    MODO_JOB_BULLET_1:
      "أنشأت ميزة حاسبة الطرود modoplus لحساب تسعير الطرود المعينة، مما يحسن تجربة العملاء ويقلل من الأخطاء.",

    MODO_JOB_BULLET_2:
      "أنشأت قالب Nextjs 13 الذي يستفيد من مكونات خادم React الجديدة، و eslint و prettier و trpc و prisma.",

    MODO_JOB_BULLET_3:
      "تعاونت مع المصمم لإنشاء دليل تنسيق لواجهة مستخدم متسقة في جميع أنحاء التطبيق.",

    MODO_JOB_BULLET_4:
      "أعدت ترتيب واجهات برمجة التطبيقات من NestJS إلى واجهات برمجة التطبيقات في NextJS لتحسين الأداء والقابلية للتوسع.",

    MODO_JOB_BULLET_5:
      "أجريت مراجعات الكود وتحملت مسؤولية تطبيق NextJS الكامل للشريحة.",

    NASCO_JOB_POSITION: "مهندس برمجيات Full Stack",
    NASCO_JOB_TITLE: "مجموعة التأمين ناسكو",
    NASCO_JOB_LOCATION: "عن بعد",
    NASCO_JOB_DATE: "يناير 2022 - الحالي",
    NASCO_JOB_BULLET_1:
      "عملت على تحويل الرحلة التأمينية الكاملة إلى رقمية في لبنان والإمارات العربية المتحدة.",
    NASCO_JOB_BULLET_2:
      "طورت منصة تأمين شاملة، وابتعدت عن جميع الخدمات التقليدية ودمجت المنطق تحت بنية خدمات صغيرة باستخدام NodeJS و ExpressJS.",
    NASCO_JOB_BULLET_3:
      "فرضت مبادئ أفضل الممارسات للواجهة الأمامية ومبادئ التصميم الذري الذي خفض وقت التطوير بنسبة 20٪.",
    NASCO_JOB_BULLET_4: "أعدت تهيئة نظام المصادقة من WSO2 إلى Red Hat SSO.",
    NASCO_JOB_BULLET_5:
      "دمجت خدمات DocuSign لتوفير خدمات توقيع المستندات المضمنة في المنصة / البريد الإلكتروني.",

    FREELANCE_JOB_POSITION: "مهندس برامج حرة",
    FREELANCE_JOB_TITLE: "عمل حر",
    FREELANCE_JOB_LOCATION: "عن بعد",
    FREELANCE_JOB_DATE: "يونيو 2018 - الحاضر",
    FREELANCE_JOB_BULLET_1:
      "طوّر تطبيقًا داخليًا للطلب والاتصال للمطاعم باستخدام React Native و Firebase Realtime Database.",
    FREELANCE_JOB_BULLET_2:
      "طوّر تطبيقًا لتصنيف المدارس باستخدام React Native.",
    FREELANCE_JOB_BULLET_3:
      "طوّر تطبيقات ويب استجابية باستخدام React و HTML و CSS و SASS.",
    FREELANCE_JOB_BULLET_4:
      "طوّر نصوصًا مخصصة لإنشاء ملفات PDF بناءً على قوالب Word من جداول البيانات باستخدام python و python-docx و PyPDF2.",
    FREELANCE_JOB_BULLET_5:
      "أنشأت متاجر إلكترونية متعددة باستخدام ووردبريس كنظام إدارة محتوى و WooCommerce و Mailchimp والمكوّنات الإضافية المخصصة باستخدام PHP. المشاريع: akl-orient.de larex-global.com letsvip.co.uk ambank.com",

    AREEBA_JOB_POSITION: "مهندس برامج كامل الدستور",
    AREEBA_JOB_TITLE: "Areeba",
    AREEBA_JOB_LOCATION: "بيروت، لبنان",
    AREEBA_JOB_DATE: "سبتمبر 2020 - يناير 2022",
    AREEBA_JOB_BULLET_1:
      "طوّر واجهات مستخدم رسومية سهلة الاستخدام باستخدام React.js و React Native.",
    AREEBA_JOB_BULLET_2: "صمم وبنى خدمات نوعية NodeJS Express.",
    AREEBA_JOB_BULLET_3:
      "عمل في بيئة تطوير Agile كمطور Full Stack في فريق وظيفي لتطوير مشاريع fintech الرائدة.",

    IDS_JOB_POSITION: "مهندس برمجيات صغير",
    IDS_JOB_TITLE: "أنظمة ديجيتال متكاملة (IDS)",
    IDS_JOB_LOCATION: "بيروت، لبنان",
    IDS_JOB_DATE: "أكتوبر ٢٠١٧ - أغسطس ٢٠١٨",
    IDS_JOB_BULLET_1:
      "كتابة الاختبارات الآلية والحفاظ على جودة الشفرة والموثوقية.",
    IDS_JOB_BULLET_2:
      "إنشاء وتحديث الوثائق الفنية، بما في ذلك دليل المستخدم ومواد مرجعية لواجهة البرمجة.",
    IDS_JOB_BULLET_3: "المشاركة في منهجيات التطوير الرشيق واجتماعات الوقوف.",

    /** Projects */
    FIRST_PROJECT_TITLE: "Areeba Merchant Portal",
    FIRST_PROJECT_SUMMARY:
      "تقدم بوابة التاجر Areeba تصميمًا مخصصًا للفواتير ذات العلامات التجارية الفواتير المتكررة وعملاء الفواتير التلقائية المخصصة للتذكير التلقائي بالدفع المتأخر ، إلخ.",
    SECOND_PROJECT_TITLE: "Zaky",
    SECOND_PROJECT_SUMMARY:
      "زكي عبارة عن منصة دفع رقمية عبر الهاتف المحمول تمكن المستهلكين والتجار ومقدمي الخدمات من إجراء واستلام مدفوعاتهم بطريقة سلسة وآمنة.",
    THIRD_PROJECT_TITLE: "ViaCard",
    THIRD_PROJECT_SUMMARY:
      "ViaCard هي الحل الأمثل لاحتياجات اللبنانيين الذين يسافرون إلى الخارج أو يتسوقون عبر الإنترنت أو حتى أولئك الذين يدرسون في الخارج.",
    FOURTH_PROJECT_TITLE: "Areeba Payment Gateway",
    FOURTH_PROJECT_SUMMARY:
      "بوابة الدفع Areeba هي تقنية يستخدمها التجار لقبول مشتريات بطاقات الخصم أو الائتمان من العملاء.",

    /** Education */
    MASTERS_MAJOR: "ماجستير العلوم في هندسة الحاسوب",
    MASTERS_UNIVERSITY: "Bahcesehir University",
    MASTERS_LOCATION: "اسطنبول، تركيا",
    MASTERS_DATE: "2018 - 2020",
    BACHELOR_MAJOR: "بكالوريوس العلوم في هندسة الحاسبات والاتصالات",
    BACHELOR_UNIVERSITY: "Lebanese International University",
    BACHELOR_LOCATION: "اسطنبول، تركيا",
    BACHELOR_DATE: "2019 - 2020",
  },
};

export default ar;
